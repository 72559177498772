<template>
  <div
    class="user-links"
    :class="className"
  >
    <b-link
      type="button"
      active-class="btn custom-blue"
      class="btn"
      :to="{ name: 'usuario-list' }"
    >
      SESI
    </b-link>

    <b-link
      type="button"
      active-class="btn custom-blue"
      class="btn"
      :to="{ name: 'usuario-empresa-list' }"
    >
      Empresas
    </b-link>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },

  props: {
    className: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
  .user-links {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
