export const statusForm = [
  {
    id: 1,
    boolValue: true,
    description: 'Ativo',
  },
  {
    id: 2,
    boolValue: false,
    description: 'Inativo',
  },
]
